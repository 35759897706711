import {_L,_L_ERRORS,_L_ERROR_COMMENTS } from './localization.js'

const ERROR_NUMBERS = [
    /* 0 */  "n/a",              
    /* 1 */  { name: _L_ERRORS.GL_ERROR_CONN, comment: _L_ERROR_COMMENTS.GL_ERROR_CONN},
    /* 2 */  { name: _L_ERRORS.GR_ERROR_SENSOR, comment: _L_ERROR_COMMENTS.GR_ERROR_SENSOR},
    /* 2 */  { name: _L_ERRORS.GL_INFO_TEST1, comment: _L_ERROR_COMMENTS.GL_INFO_TEST1}
]

const ConstructErrorList = (errCodes) => {
    
    const newErrList =  errCodes.map( (item) => {
      if (item.id < ERROR_NUMBERS.length) {
        return { type: item.type, name: ERROR_NUMBERS[item.id].name, comment: ERROR_NUMBERS[item.id].comment};
      } else {
        return { type: item.type, name: 'n/a', comment: 'n/a'};
      }
    })
    return newErrList;
  }


export { ConstructErrorList };