const _L = {

    GL_CANCEL: "Cancel",
    GL_CONFIRM: "Confirm",
    GL_ON: "On",
    GL_OFF: "Off",
    GL_SHOW: "Show",
    GL_HIDE: "Hide",
    GL_YES: "Yes",
    GL_NO: "No",
    GL_OK: "OK",

    GL_OVERVIEW: "Overview",

    //Home page
    GL_HOME_PAGE: "Overview",
    GL_HOME_PAGE_MENU: "Overview",
    GL_ABOUT_PAGE: "About",
    GL_START_WEIGHING: "Start\nWeighing",
    
    GL_STOP_WEIGHING_2LINES: "Stop\nWeighing",

    GL_WEIGHING_RUNNING: "Weighing started",
    GL_WEIGHING_IS_STOPPED:"Weighing stopped",
    GL_WEIGHING_SENSOR_NOT_WORKING: "Not working",

    //Start Weighing
    GL_START_DAY: "Start Day",
    GL_INITIAL_WEIGHT:"Initial Weight",
    GL_FLOCK_NAME: "Flock Name (optional)",
    GL_WEIGHING_SCHEDULE: "Weighing Schedule (optional)",
    //Stop Weighing
    GL_STOP_WEIGHING_QUESTION: "Are you sure you want to stop the weighing session in progress?",
    GL_WEIGHING_INACCURACY : "This could cause innacuracies in your data.",
    
    //Overview
    GL_AVERAGE_WEIGHT: "Average Weight",
    GL_ACTUAL_WEIGHT: "Actual Weight",
    GL_AGE: "Age",
    GL_TARGET_WEIGHT: "Target Weight",
    GL_PREDICTED_WGT: "Predicted Wgt.",
    GL_LAST_WEIGHT: "Last Weight",
    GL_AVERAGE_WGT: "Average Wgt.",
    GL_STANDARD_WGT: "Standard Dev.",
    GL_DAILY_GAIN: "Daily Gain",
    GL_SUM_OF_ALL: "Total Weight",
    GL_UNIFORMITY: "Uniformity",
    GL_VARIATION: "Variation",
    GL_COUNT:"Count",
    GL_EVERY_DAY_TIME: "Every day 0:00-23:59",
    GL_OVERWIEW: "Overview",
    GL_SENSOR_STATUS: "Sensor Status",
    GL_NO_DATA: "-",

    
    // Statistics
    GL_STATISTICS_MENU: "Statistics",

    //Top menu
    GL_TOP_SETTINGS : "Settings",
    GL_SUB_CONNECTIVITY : "Connectivity",

    //Scale Settings
    GL_SCALE_SETTINGS_MENU : "Scale",
    GL_SCALE_SETTINGS : "Scale Settings",  
    GL_SCALE_NAME : "Scale Name",
    GL_SCALE_NAME_PLACEHOLDER: "Enter Scale Name",
    GL_LANGUAGE : "Language",
    GL_LANGUAGE_PLACEHOLDER : "Choose Language",
    
    //todo: vytvorit funkci vracejici list, ktery pouziji primo k vyplneni comboboxu v reactu
    //iso639_1.c
    //[ISO639_1_CZECH] = { .full_name = "Czech", .iso639_1_code = "cs"},
    //[ISO639_1_ENGLISH] = { .full_name = "English", .iso639_1_code = "en"},
    //[ISO639_1_SLOVAK] = { .full_name = "Slovak", .iso639_1_code = "sk"},


    GL_UNITS : "Units",
        GL_UNITS_GRAMS: "Grams",
        GL_UNITS_POUNDS: "Pounds",

    GL_UNITS_SIGN_GRAMS: "g",
    GL_UNITS_SIGN_POUNDS: "lb",


    GL_UNITS_PLACEHOLDER: "Choose Unit",

    GL_DATE_FORMAT : "Date Format",
        //todo: zatim mam natvrdo, lokalizovat??
        
    GL_TIME_FORMAT : "Time Format",
        //lv_dropdown_add_option(dd, gui_lang_get_text(GL_FORMAT_24HRS), 0);
        GL_FORMAT_24HRS : "24-Hour",

        //lv_dropdown_add_option(dd, gui_lang_get_text(GL_FORMAT_12HRS), 1);
        GL_FORMAT_12HRS: "12-Hour",
        
    GL_TIME_FORMAT_PLACEHOLDER: "Choose Time Format",

    GL_DATE_AND_TIME : "Date and Time",
    GL_UPDATE : "Update",
    GL_BACK : "Back",

    //Weighing Settings
    GL_WEIGHING_SETTINGS_PAGE: "Weighing Settings",
    GL_WEIGHING_SETTINGS_MENU: "Weighing",
    GL_COUNTING_AGE: "Counting Age",
    GL_COUNTING_AGE_PLACEHOLDER: "Choose Counting Age",
    GL_DAY: "day",
    GL_DAYS: "days",
    GL_DAYS_MENU: "Days",
    GL_WEEK: "week",
    GL_WEEKS: "weeks",
    GL_WEEKS_MENU: "Weeks",
    
    GL_WEIGHING_MODE: "Weighing Mode",
    GL_WEIGHING_MODE_PLACEHOLDER: "Choose Weighing Mode",
    GL_AUTOMATIC_WO_GAIN: "Automatic without Gain",
    GL_AUTOMATIC_W_GAIN:  "Automatic with Gain",
    

    GL_WEIGHING_PREFERENCE: "Weighing Preference",
    GL_WEIGHING_PREFERENCE_PLACEHOLDER: "Choose Weighing Preference",

    GL_WEIGHING_STANDARD: "Standard",
    GL_WEIGHING_CUSTOMIZED: "Customized",
    
    GL_SHOW_ADVANCED_SETTINGS_PLACEHOLDER: "Choose Advanced Settings",

    //Advanced Weighing Settings
    GL_ADVANCED_SETTINGS_PAGE: "Advanced Settings",
    GL_ADVANCED_SETTINGS_MENU: "Advanced",
    GL_WEIGHT_CORRECTION: "Weight Correction",
    GL_CORRECTION: "Correction",
    GL_CORRECTION_START: "Correction Start",
    GL_CORRECTION_END: "Correction End",
    GL_STATISTICS: "Statistics",
    GL_UNIFORMITY_RANGE: "Uniformity Range",
    GL_WEIGHING_MARGINS: "Weighing Margins",
    GL_ABOVE_TARGET: "Above Target",
    GL_BELOW_TARGET: "Below Target",
    GL_WEIGHING_ALGORITHM:  "Weighing Algorithm",
    GL_FILTER: "Filter",
    GL_STABILIZATION_RANGE: "Stabilization Range",
    GL_STABILIZATION_TIME: "Stabilization Time (s)",

    //Ethernet Settings
    GL_ETHERNET_SETTINGS_PAGE: "Ethernet Settings",
    GL_ETHERNET_SETTINGS_MENU: "Ethernet",
    GL_USING_DHCP: "Use DHCP",
    GL_USING_DHCP_PLACEHOLDER: "Choose DHCP",
    GL_IP_ADDRESS: "IP Address",
    GL_SUBNET_MASK: "Subnet Mask",
    GL_DEFAULT_GATEWAY: "Default Gateway",
    GL_DNS_SERVER: "DNS Server",
    GL_MAC_ADDRESS: "MAC Address",

    //WiFi Settings
    GL_WIFI_SETTINGS_MENU: "WiFi",
    GL_WIFI_SETTINGS_PAGE: "WiFi Settings",
    GL_WIFI_ENABLED: "WiFi Enabled",
    GL_WIFI_ENABLED_PLACEHOLDER: "Enable WiFi",
    GL_WIFI_SSID: "SSID",
    GL_WIFI_KEY: "Key",

    // Server // Cloud Connectivity
    GL_SERVER_SETTINGS_PAGE: "Server Settings",
    GL_SERVER_SETTINGS_MENU: "Server",
    GL_USING_SERVER: "Use Server",
    GL_USING_SERVER_PLACEHOLDER: "Choose Using Server",
    GL_HTTP_URL: "HTTP URL",
    GL_AUTHORIZATION_TOKEN: "Authorization Token",
    

    //Calibrate Scale
    GL_CALIBRATE_SCALE: "Calibrate Scale",
    GL_CALIBRATE_STATUS: "Status",
    GL_CALIBRATE_SCALE_2LINES: "Calibrate\nScale",
    GL_GET_READY_PLATFORM: "Prepare Platform",
    GL_SET_TARGET_WEIGHT: "Set Target Weight",
    GL_STABILISING_PLATFORM: "Stabilising Platform",
    GL_CALIBRATE_PLATFORM: "Calibrate Platform",
    GL_START_WEIGHING_WITH_PLATFORM: "Start Weighing",
    GL_MAKE_SURE_THE_PLATFORM: "Make sure the platform is hung up and\nwith no dirt or objects on it.",
    GL_INFO_BUTTON_CONTROL: "Now you can continue calibration using the keyboard on the scale.",
    GL_PLACE_WEIGHT_ON_PLATFORM: "Place Weight {} on Platform",
    GL_TARGET_WEIGHT_ON_PLATFORM: "Target Weight on Platform",
    GL_PLEASE_WAIT: "Please wait...",
    GL_MEASURING_EMPTY_PLATFORM: "Measuring empty platform...",
    GL_MEASURING_WEIGHT_ON_PLATFORM: "Measuring...",
    GL_PLATFORM_IS_READY: "Platform is ready.",
    GL_YOUR_PLATFORM_IS_READY_TO_USE: "Your platform is ready to use.",
    GL_NEXT_STEP: "Next Step",
    GL_CANCEL: "Cancel",


    //Help
    GL_HELP_MENU: 'Help',
    GL_CONTACT_INFO: "Contact Info",
    GL_CONTACT_1: "Address 1",
    GL_CONTACT_2: "Address 2",
    GL_CONTACT_3: "Address 3",
    GL_CONTACT_4: "Address 4",
    GL_APPLICATION_INFO: "Version Info",
    GL_VERSION : "Version",
    GL_BUILD : "Build"
    
}

const _L_ERRORS = {
    GL_ERROR_CONN: "Connection Error",
    GR_ERROR_SENSOR: "Sensor Error",
    GL_INFO_TEST1: "Info 1"
}

const _L_ERROR_COMMENTS = {
    GL_ERROR_CONN: "Check the internet connection",
    GR_ERROR_SENSOR: "Check the External Sensor connection",
    GL_INFO_TEST1: "Comment for Info 1"
}


export {_L,_L_ERRORS,_L_ERROR_COMMENTS}

