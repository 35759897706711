import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRestApiUrl } from '../config.js'
import { useScaleParams } from "../hooks/useScaleParams.js";
import { _L } from '../localization.js'

const Help = () => {

  const {versionInfo} = useScaleParams();

  const stats = [
    { type:'contact_info', name: _L.GL_CONTACT_1, stat: "VEIT Electronics", condition: true },
    { type:'contact_info', name: _L.GL_CONTACT_2, stat: "Modřická 52", condition: true },
    { type:'contact_info', name: _L.GL_CONTACT_3, stat: "664 48 Moravany", condition: true },
    { type:'contact_info', name: _L.GL_CONTACT_4, stat: "Czech Republic", condition: true },
    { type:'application_info', name: _L.GL_VERSION, stat: versionInfo.split("build:")[0], condition: true },
    { type:'application_info', name: _L.GL_BUILD, stat: versionInfo.split("build:")[1], condition: true }
  ]

  return (
    <div>
    <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
        <h2 className="text-base font-semibold leading-6 text-gray-900">{_L.GL_CONTACT_INFO}</h2>
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {stats.map((item) => (
                     item.type === 'contact_info' && item.condition && (
                        <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                        </div>
                    )
                ))}
            </dl>
        </div>
        <div className="relative my-10">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>        
      </div>        

        <div className="relative flex justify-center">
        <span className="bg-gray-50 px-2 text-sm text-gray-500">{_L.GL_APPLICATION_INFO}</span>
        </div>

        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {stats.map((item) => (
                     item.type === 'application_info' && item.condition && (
                        <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                        </div>
                    )
                ))}
            </dl>
        </div>
    </div>
    </div>
  )
}

export default Help
