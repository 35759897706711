import React from 'react';

// create the context
export const ParamsContext = React.createContext(undefined);

export const ParamsProvider = ({ children }) => {
    // the reactive values

    const [scaleName, setScaleName] = React.useState("n/a");
    const [versionInfo, setVersionInfo] = React.useState("n/a");
    const [use_pounds_instead_of_grams,setUse_pounds_instead_of_grams] = React.useState(false);
    
    const [errorList,setErrorList] = React.useState([
            // { type:'error', name: "Sensor Error", comment: "Sensor is not working!" },
            //{ type:'error', name: "Connection Error", comment: "Export to cloud failed! x"},
            //{ type:'info', name: "Some Info", comment: "Some info ... "}
            ]
            )

    // wrap the application in the provider with the intialized context
    return (
        <ParamsContext.Provider value={
            {
                scaleName,setScaleName,
                versionInfo, setVersionInfo,
                use_pounds_instead_of_grams,setUse_pounds_instead_of_grams,
                errorList,setErrorList
            }}>
            {children}
        </ParamsContext.Provider>
    )
}

export default ParamsContext;
