import { useContext } from 'react';

import ParamsContext from '../context/ParamsProvider';

export const useScaleParams = () => {
    return useContext(ParamsContext);
}

export default useScaleParams;

