import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRestApiUrl } from '../config.js'
import { useScaleParams } from "../hooks/useScaleParams.js";
import { _L } from '../localization.js'

const NavConnectivity = () => {



    const navItems = [
      { name: _L.GL_ETHERNET_SETTINGS_MENU, icon: "ethernet", link:'connectivity/ethernet-settings', visible: true },
      { name: _L.GL_WIFI_SETTINGS_MENU, icon: "wifi", link:'connectivity/wifi-settings', visible: true },
      { name: _L.GL_SERVER_SETTINGS_MENU, icon: "server", link:'connectivity/server-settings', visible: true },
    ]
  
  return (
      <div>
          <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
              <h2 className="text-base font-semibold leading-6 text-gray-900">{_L.GL_SUB_CONNECTIVITY}</h2>
              <div>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      {navItems.map((item) => (
                          item.visible && (
                              <Link to={`/${item.link}`} key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow text-center sm:p-6">
                                  <dt className="text-sm font-medium text-gray-500 truncate">
                                      <img src={`/icons/${item.icon}.svg`} alt={`${item.name} icon`} className="inline-block" />
                                  </dt>
                                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.name}</dd>
                              </Link>
                          )
                      ))}
                  </dl>
              </div>
          </div>
      </div>
  )
}

export default NavConnectivity