import React from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { getRestApiUrl } from '../config'
import { _L } from '../localization.js'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

function StopWeighing() {
  

  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()

    console.log('SUBMIT!!!!')

    axios.post(`${getRestApiUrl()}/api/bat2/weighing_control/stop`)
    .then(res => {
        console.log(res)
        navigate('/')
    })
    .catch( err => console.log(err))
  }

  return (
<div>
    <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
    <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
    <div className="px-6 py-5 sm:p-12 col-span-1 sm:col-span-2">
          <form onSubmit={handleSubmit}>
          <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="mt-2">
                      <p className="text-lg font-semibold text-gray-900">
                      {_L.GL_STOP_WEIGHING_QUESTION}
                      </p>
                      <p className="text-sm text-gray-500">
                      {_L.GL_WEIGHING_INACCURACY}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                  <button
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"

                  >
                    {_L.GL_CONFIRM}
                  </button>
                  <Link to="/"
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                  >
                    {_L.GL_CANCEL}
                  </Link>
                </div>

            </form>            
        </div>

    </div>
    </div>
    </div>
    
  )
}

export default StopWeighing