
const useBeagleBoneWs = false
//const beagleBoneIp="192.168.1.119"


const isProductionVersion = () => {
  return process.env.NODE_ENV === 'production'
}

const getWsUrl = () => {
    
 
  console.log('getWsUrl....',window.location)
    const url = document.createElement('a')
    url.setAttribute('href',window.location)
    console.log(`v1 HOSTNAME=${url.hostname} PORT=${url.port} PROTOCOL=${url.protocol} isProductionVersion()=${isProductionVersion()}`)

    return (
      `ws://${url.hostname}:80`
    )

    /*
  return (
    `ws://${beagleBoneIp}:80`
  ) 
    */

}

const getRestApiUrl = () => {
  //console.log('getRestApiUrl() -> ',window.location)
    const url = document.createElement('a')
    url.setAttribute('href',window.location)
    //console.log(`HOSTNAME=${url.hostname} `)
    console.log(`HOSTNAME=${url.hostname} isProductionVersion()=${isProductionVersion()}`)

    
      return (
        `http://${url.hostname}:80`
      )

/*
  //varianta pri bezicim express serveru na localhost:4000
  return (
    `http://${url.hostname}:4000`
  )
*/
/*      

  return (
    `http://${beagleBoneIp}:80`
)
    */
}


export { getWsUrl, getRestApiUrl, isProductionVersion }
