import Help from './components/Help';
import Home from './components/Home';
import Navbar from './components/Navbar';
import ScaleSettings from './components/ScaleSettings';
import WeighingSettings from './components/WeighingSettings';
import EthernetSettings from './components/EthernetSettings';
import ServerSettings from './components/ServerSettings';
import WifiSettings from './components/WifiSettings';
import StartWeighing from './components/StartWeighing';
import StopWeighing from './components/StopWeighing';
import CalibrateScale from './components/CalibrateScale';
import NavSettings from './components/NavSettings';
import NavConnectivity from './components/NavConnectivity';
import './styles.css'; // Import Tailwind
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { isProductionVersion } from './config';
import {Route, Routes } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { useScaleParams } from "./hooks/useScaleParams";
import axios from "axios";
import { getRestApiUrl } from "./config";

function App() {

  const {scaleName,setScaleName,errorList} = useScaleParams();

  useEffect(() => {
      axios
        .get(`${getRestApiUrl()}/api/bat2/settings/scale`)
        .then((res) => {
          console.log(JSON.stringify(res.data));
          setScaleName(res.data["scale_name"]);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);
  
  document.title = scaleName;

  //console.log(`errorList=${JSON.stringify(errorList)}`);

  return (
    <div className="App">
      <Navbar />
      
        <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">

        {errorList.map((item) => (
            <>
            {item.type === 'info' && (
              <div key={item.name} className="rounded-md mx-auto my-3 max-w-5xl bg-blue-100 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-blue-900">
                      {item.name}: <span>{item.comment}</span>
                    </p>
                  </div>
                  <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-blue-100 p-1.5 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50"
                      >
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
            }
            {
            item.type === 'error' && (
              <div className="rounded-md mx-auto my-3 max-w-5xl bg-indigo-100 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-indigo-900">
                      {item.name}: <span>{item.comment}</span>
                    </p>

                    {/* NAPR. chybova hlaska, kdyz sensor status neni ok { type:'status', name: _L.GL_SENSOR_STATUS, stat: overview.weighing_sensor_is_okay? (_L.GL_OK) : (_L.GL_WEIGHING_SENSOR_NOT_WORKING), condition: true }, */}


                  </div>
                </div>
              </div>
            )
          }
          </>
        ))}
        </div>
      


      
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/settings/scale-settings' element={<ScaleSettings/>} />
          <Route path='/settings/weighing-settings' element={<WeighingSettings/>} />
          <Route path='/connectivity/ethernet-settings' element={<EthernetSettings/>} />
          <Route path='/connectivity/wifi-settings' element={<WifiSettings/>} />
          <Route path='/connectivity/server-settings' element={<ServerSettings/>} />
          <Route path='/start-weighing' element={<StartWeighing/>} />
          <Route path='/stop-weighing' element={<StopWeighing/>} />
          <Route path='/calibrate-scale' element={<CalibrateScale/>} />
          <Route path='/settings' element={<NavSettings/>} />
          <Route path='/connectivity' element={<NavConnectivity/>} />
          <Route path='/help' element={<Help/>} />
        </Routes>
    </div>
  );
}

export default App;
