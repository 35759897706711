import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRestApiUrl } from "../config";
import { _L } from "../localization.js";
import { RadioGroup } from "@headlessui/react";
import { useScaleParams } from "../hooks/useScaleParams.js";

const languages = [
  { name: "English", value: "en", available: true },
  { name: "Česky", value: "cz", available: true },
];

const units = [
  { name: _L.GL_UNITS_GRAMS, value: false, available: true },
  { name: _L.GL_UNITS_POUNDS, value: true, available: true },
];


const timeFormats = [
    { name: _L.GL_FORMAT_24HRS, value: false, available: true },
    { name: _L.GL_FORMAT_12HRS, value: true, available: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ScaleSettings = () => {
  const {setScaleName} = useScaleParams();
  const [values, setValues] = useState([]);
  const navigate = useNavigate();

  const [mem, setMem] = useState(languages[0]);
  const [selectedUnit, setSelectedUnit] = useState(units[0]);
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormats[0]);

  useEffect(() => {
    axios
      .get(`${getRestApiUrl()}/api/bat2/settings/scale`)
      .then((res) => {
        setValues(res.data);

        const getItem = (arr,value) => {
          const ind = arr.map(e => e.value).indexOf(value)
          return arr[ind==-1?0:ind]
        }

        setMem(getItem(languages,res.data.language_ISO639_1));
        setSelectedUnit(getItem(units,res.data.use_pounds_instead_of_grams));
        setSelectedTimeFormat(getItem(timeFormats,res.data.use_12hour_format));

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleUpdate = (event) => {
    event.preventDefault();

    let newValues = { ...values, 
        language_ISO639_1: mem.value,
        use_pounds_instead_of_grams: selectedUnit.value,
        use_12hour_format: selectedTimeFormat.value
    }

    setScaleName(values.scale_name);

    axios
      .post(`${getRestApiUrl()}/api/bat2/settings/scale`, newValues)
      .then((res) => {
        console.log(res);
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
        <header className="mx-auto my-3 max-w-5xl mt-10">
          <h1 className="text-2xl font-semibold leading-tight tracking-tight text-black">
            {_L.GL_SCALE_SETTINGS}
          </h1>
        </header>
        <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
            <div className="px-6 py-5 sm:p-6 col-span-1 sm:col-span-2">
              <form onSubmit={handleUpdate}>
                <label
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {_L.GL_SCALE_NAME}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="scale_name"
                    placeholder={_L.GL_SCALE_NAME_PLACEHOLDER}
                    value={values.scale_name}
                    onChange={(e) => 
                      setValues({ ...values, scale_name: e.target.value })
                    }
                    className="form-control block w-full rounded-md border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="mt-6 flex items-center justify-between">
                  <label className="text-sm font-medium leading-6 text-gray-900">
                    {_L.GL_LANGUAGE}
                  </label>
                </div>

                <div>
                  <RadioGroup value={mem} onChange={setMem} className="mt-2">
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_LANGUAGE_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                       {/* language_ISO639_1 */}
                      {languages.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <div className="mt-6 flex items-center justify-between">
                  <label className="text-sm font-medium leading-6 text-gray-900">
                    {_L.GL_UNITS}
                  </label>
                </div>

                <div>
                  <RadioGroup
                    value={selectedUnit}
                    onChange={setSelectedUnit}
                    className="mt-2"
                  >
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_UNITS_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {units.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>


                <div className="mt-6 flex items-center justify-between">
                  <label className="text-sm font-medium leading-6 text-gray-900">
                    {_L.GL_DATE_FORMAT}
                  </label>
                </div>
                <div>
                  <select
                    name="date_format"
                    id="date_format"
                    className="mt-2 block w-full rounded-md border-0 py-3 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    defaultValue="MM/DD/YYYY"
                    value={values.date_format}
                    onChange={(e) =>
                      setValues({ ...values, date_format: e.target.value })
                    }
                  >
                    <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                    <option value="MM.DD.YYYY">MM.DD.YYYY</option>
                    <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                    <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                    <option value="DD.MM.YYYY">DD.MM.YYYY</option>
                  </select>
                </div>

                <div className="mt-6 flex items-center justify-between">
                  <label className="text-sm font-medium leading-6 text-gray-900">
                    {_L.GL_TIME_FORMAT}
                  </label>
                </div>
                <div>
                  <RadioGroup
                    value={selectedTimeFormat}
                    onChange={setSelectedTimeFormat}
                    className="mt-2"
                  >
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_TIME_FORMAT_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {timeFormats.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                <div>
                    <label htmlFor="date-time" className="mt-6 block text-sm font-medium leading-6 text-gray-900">
                        {_L.GL_DATE_AND_TIME}
                    </label>
                    <div className="mt-2">
                        <input 
                        type="datetime-local" 
                        name="date-time" id="date-time" 
                        value={values.date_time}
                        onChange={(e) =>
                          setValues({ ...values, date_time: e.target.value })
                        } 
                        className="block w-full rounded-md border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div className="buttons mt-6">
                  <button type="submit"
                    className="btn btn-info my-2 mr-3 w-full sm:w-72 text-center rounded-md bg-green-600 px-3.5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" to="/start-weighing">
                    {_L.GL_UPDATE}
                  </button>
                </div>
              </form>
            </div>
          </div>
          </div>
        </div>
        </div>
    
  );
};

export default ScaleSettings;
