import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRestApiUrl } from '../config'
import { _L } from '../localization.js'
import { RadioGroup } from "@headlessui/react";

const DHCP = [
  { name: _L.GL_ON, value: true, available: true },
  { name: _L.GL_OFF, value: false, available: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EthernetSettings = () => {
    const [values,setValues] = useState([])
    const navigate = useNavigate()
    
    const [selectedDHCP, setDHCP] = useState(DHCP[0]);

    useEffect(() => {
        axios.get(`${getRestApiUrl()}/api/bat2/settings/ethernet`)
        .then(res => {
            console.log(res.data)
            setValues(res.data)

            const getItem = (arr,value) => {
              const ind = arr.map(e => e.value).indexOf(value)
              return arr[ind==-1?0:ind]
            }
    
            setDHCP(getItem(DHCP,res.data.dhcp_enable));


        })
        .catch( err => {
            console.log(err)
        })
      },[])


    const handleUpdate = (event) => {
        event.preventDefault()

        let newValues = { ...values, 
          dhcp_enable: selectedDHCP.value
        }
  
        axios.post(`${getRestApiUrl()}/api/bat2/settings/ethernet`,newValues)
        .then(res => {
            console.log(res)
            navigate('/')
        })
        .catch( err => console.log(err))
      }    

    const fontClassNames = () => {
      if (selectedDHCP.value) {
        return 'text-gray-400';
      } else {
        return 'text-black';
      }
    }

  return (
  <div>
    <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
      <header className="mx-auto my-3 max-w-5xl mt-10">
        <h1 className="text-2xl font-semibold leading-tight tracking-tight text-black">
          {_L.GL_ETHERNET_SETTINGS_PAGE}
        </h1>
      </header>
      <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
        <div className="px-6 py-5 sm:p-6 col-span-1 sm:col-span-2">
          <form onSubmit={handleUpdate}>
             <h2 className="font-semibold">{_L.GL_ETHERNET_SETTINGS}</h2>
              <div className="mt-4">
                <label htmlFor="dhcp-enabled" className="block text-sm font-medium text-gray-700">
                  {_L.GL_USING_DHCP}
                </label>
                <div className="mt-1">
                <RadioGroup value={selectedDHCP} onChange={setDHCP} className="mt-2">
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_USING_DHCP_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {DHCP.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="ip-address" className="block text-sm font-medium text-slate-700">
                  {_L.GL_IP_ADDRESS}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="ip-address"
                    id="ip-address"
                    value={values.ipv4_address}
                    disabled={selectedDHCP.value}
                    onChange={e => setValues({...values, ipv4_address: e.target.value})}
                    className={`form-control block w-full rounded-md border-0 py-2.5 px-3 ${fontClassNames()} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6`}
                    placeholder="0.0.0.0"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="subnet-mask" className="block text-sm font-medium text-gray-700">
                  {_L.GL_SUBNET_MASK}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="subnet-mask"
                    id="subnet-mask"
                    value={values.ipv4_mask}
                    disabled={selectedDHCP.value}
                    onChange={e => setValues({...values, ipv4_mask: e.target.value})}
                    className={`form-control block w-full rounded-md border-0 py-2.5 px-3 ${fontClassNames()} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6`}
                    placeholder="0.0.0.0"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="default-gateway" className="block text-sm font-medium text-gray-700">
                  {_L.GL_DEFAULT_GATEWAY}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="default-gateway"
                    id="default-gateway"
                    value={values.ipv4_gateway}
                    disabled={selectedDHCP.value}
                    onChange={e => setValues({...values, ipv4_gateway: e.target.value})}
                    className={`form-control block w-full rounded-md border-0 py-2.5 px-3 ${fontClassNames()} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6`}
                    placeholder="0.0.0.0"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="dns-server" className="block text-sm font-medium text-gray-700">
                  {_L.GL_DNS_SERVER}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="dns-server"
                    id="dns-server"
                    value={values.ipv4_dns}
                    disabled={selectedDHCP.value}
                    onChange={e => setValues({...values, ipv4_dns: e.target.value})}
                    className={`form-control block w-full rounded-md border-0 py-2.5 px-3 ${fontClassNames()} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6`}
                    placeholder="0.0.0.0"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="mac-address" className="block text-sm font-medium text-gray-700">
                  {_L.GL_MAC_ADDRESS}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="mac-address"
                    id="mac-address"
                    value={values.mac}
                    disabled="true"
                    onChange={e => setValues({...values, mac: e.target.value})}
                    className="form-control block w-full rounded-md border-0 py-2.5 px-3 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                    placeholder="00.00.00.00.00.00"
                  />
                </div>
              </div>
              <div className="buttons mt-6">
                  <button type="submit"
                    className="btn btn-info my-2 mr-3 w-full sm:w-72 text-center rounded-md bg-green-600 px-3.5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/start-weighing">
                    {_L.GL_UPDATE}
                  </button>
                </div>

            </form>
        </div>
      </div>
      </div>
    </div>
  </div>
  )
}

export default EthernetSettings
