import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRestApiUrl } from '../config'
import { _L } from '../localization.js'
import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EnableServer = [
  { name: _L.GL_ON, value: true, available: true },
  { name: _L.GL_OFF, value: false, available: true },
];


const ServerSettings = () => {
    const [values,setValues] = useState([])
    const navigate = useNavigate()

    const [selectedEnableServer, setEnableServer] = useState(EnableServer[0]);

    useEffect(() => {
        axios.get(`${getRestApiUrl()}/api/bat2/settings/server`)
        .then(res => {
            console.log(res.data)
            setValues(res.data)

            const getItem = (arr,value) => {
              const ind = arr.map(e => e.value).indexOf(value)
              return arr[ind==-1?0:ind]
            }
            setEnableServer(getItem(EnableServer,res.data.enable));


        })
        .catch( err => {
            console.log(err)
        })
      },[])


    const handleUpdate = (event) => {
        event.preventDefault()

        let newValues = { ...values, 
          enable: selectedEnableServer.value
        }

        axios.post(`${getRestApiUrl()}/api/bat2/settings/server`,newValues)
        .then(res => {
            console.log(res)
            navigate('/')
        })
        .catch( err => console.log(err))
      }    

      const fontClassNames = () => {
        if (selectedEnableServer.value) {
          return 'text-black';
        } else {
          return 'text-gray-400';          
        }
      }
  

  return (
<div>
  <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
    <header className="mx-auto my-3 max-w-5xl mt-10">
      <h1 className="text-2xl font-semibold leading-tight tracking-tight text-black">
        {_L.GL_SERVER_SETTINGS_PAGE}
      </h1>
    </header>
    <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
        <div className="px-6 py-5 sm:p-6 col-span-1 sm:col-span-2">
          <form onSubmit={handleUpdate}>
          <div className="mt-4">
                <label htmlFor="dhcp-enabled" className="block text-sm font-medium text-gray-700">
                  {_L.GL_USING_SERVER}
                </label>
                <div className="mt-1">
                <RadioGroup value={selectedEnableServer} onChange={setEnableServer} className="mt-2">
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_USING_SERVER_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {EnableServer.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            <div className="mt-4">
              <label htmlFor="http-url" className="block text-sm font-medium text-gray-700">
                {_L.GL_HTTP_URL}
              </label>
              <div className="mt-1">
                <input
                  type="url"
                  name="http-url"
                  id="http-url"
                  value={values.http_post_url}
                  disabled={!selectedEnableServer.value}
                  onChange={e => setValues({...values, http_post_url: e.target.value})}
                  className={`form-control block w-full rounded-md border-0 py-2.5 px-3 ${fontClassNames()} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6`}
                  placeholder="http://"
                />
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="authorization-token" className="block text-sm font-medium text-gray-700">
                {_L.GL_AUTHORIZATION_TOKEN}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name='authorization-token'
                  id="authorization-token"
                  value={values.authorization_token}
                  disabled={selectedEnableServer.value}
                  onChange={e => setValues({...values, authorization_token: e.target.value})}
                  className={`form-control block w-full rounded-md border-0 py-2.5 px-3 ${fontClassNames()} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6`}
                  placeholder=""
                />
              </div>
            </div>

            <div className="buttons mt-6">
                <button type="submit"
                  className="btn btn-info my-2 mr-3 w-full sm:w-72 text-center rounded-md bg-green-600 px-3.5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/start-weighing">
                  {_L.GL_UPDATE}
                </button>
              </div>

            </form>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default ServerSettings
