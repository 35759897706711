import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getRestApiUrl, getWsUrl } from "../config";
import { useScaleParams } from "../hooks/useScaleParams";
import { _L } from "../localization.js";

import { CheckIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid'

const CalibrateScale = () => {
  const [wizardState, setWizardState] = useState(1);
  //const [immediateWizardState, setImmediateWizardState] = useState(1);
  const [preferredWeight, setPreferredWeight] = useState(0.0);
  const [recvState, setRecvState] = useState({});
  const [weighingState, setWeighingState] = useState(false);
  const {use_pounds_instead_of_grams} = useScaleParams();
  const [error, setError] = useState("");  // Uložení chybové zprávy
  
  const navigate = useNavigate();
  const ws = useRef(null);

  const weightUnits = () => {
    if (!use_pounds_instead_of_grams) {
        return _L.GL_UNITS_SIGN_GRAMS;
    } else {
        return _L.GL_UNITS_SIGN_POUNDS;
    }
}


  useEffect(() => {
    const values = {
      calibration_state: 0,
    };

    axios
      .get(`${getRestApiUrl()}/api/bat2/calibration_control`)
      .then((res) => {
        setPreferredWeight(res.data.reference_weight_grams)
        axios
          .post(`${getRestApiUrl()}/api/bat2/calibration_control`, values)
          .then((res) => {
         	//console.log(res);
          })
          .catch((err) => console.log(err));

      })
      .catch((err) => console.log(err));

    const socket = new WebSocket(getWsUrl());

    socket.onopen = () => {
      console.log("ws opened");
      //setConnected(true)
    };

    socket.onclose = () => {
      console.log("ws closed");
      //setConnected(false)
    };

    ws.current = socket;

    return () => {
      socket.close();
      sendCalibrationDone();
    };
  }, []);

  // Funkce pro validaci vstupu
  const validatePreferredWeight = () => {
    const value = parseFloat(preferredWeight);
    if (isNaN(value) || value <= 0) {
      setError("Must be greater than 0"); // Nastav chybovou zprávu
      return false;
    }
    setError(""); // Vymaž chybovou zprávu, pokud je vstup správný
    return true;
  };

  // Funkce na kliknutí Next Step
  const handleNextStep = (wizardState) => {
    if (validatePreferredWeight()) {
      onReady(wizardState); // Pokud je validace úspěšná, přejdi na další krok
    }
  };



  const onReady = (newState) => {
    const values = {
      reference_weight_grams: +preferredWeight,
      calibration_state: wizardState,
    };

    console.log(`onReady ${JSON.stringify(values)}`);

    axios
      .post(`${getRestApiUrl()}/api/bat2/calibration_control`, values)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));

    setWizardState(wizardState + 1);

    ws.current.onmessage = (event) => {
      const obj = JSON.parse(event.data);
      console.log(`onmessage: wizState= ${wizardState} ; ${JSON.stringify(obj.calibration)}`);
      setWeighingState(obj.data.currentState === "WEIGHING_RUNNING");

      if (obj.calibration != null) {
        setRecvState(obj.calibration);
        //setImmediateWizardState(obj.calibration.state);
        
        if (obj.calibration.btnState === false) {
          // The User continues on Web Page ...
          if (obj.calibration.state === wizardState + 1) {
            setWizardState(obj.calibration.state + 1);
            //console.log("VYPINAM WS.CURRENT.ONMESSAGE");
            //ws.current.onmessage = null;
          }
        } else {
          // The User continues from the keyboard
          setWizardState(obj.calibration.state+1);
          //console.log(`NASTAVUJI wizardState NA ${obj.calibration.state} + 1`);
        }
      }
    };
  };

  const onOverview = () => {
    sendCalibrationDone();
    navigate("/");
  };

  const onStartWeighing = () => {
    sendCalibrationDone();
    navigate("/start-weighing");
  };


  const onCancel = () => {
    sendCalibrationCanceled();
    navigate("/");
  };


  const sendCalibrationDone = () => {
    const values = {
      reference_weight_grams: +preferredWeight,
      calibration_state: 6
    };
    console.log(`sendCalibrationDone ${JSON.stringify(values)}`);

    axios
      .post(`${getRestApiUrl()}/api/bat2/calibration_control`, values)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const sendCalibrationCanceled = () => {
    const values = {
      calibration_state: -1,
    };

    axios
      .post(`${getRestApiUrl()}/api/bat2/calibration_control`, values)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const WIZARD_STATE_SET_TARGET_WEIGHT = 1;
  const WIZARD_STATE_GET_READY_PLATFORM = 2;
  const WIZARD_STATE_STABILISING_PLATFORM_EMPTY = 3;
  const WIZARD_STATE_PLACE_WEIGHT_ON_PLATFORM = 4;
  const WIZARD_STATE_STABILISING_PLATFORM_REFERENCE = 5;
  const WIZARD_STATE_STABILISING_PLATFORM_FINISHED = 6;

  const steps = [
    {
      ident : WIZARD_STATE_SET_TARGET_WEIGHT,
      name: _L.GL_SET_TARGET_WEIGHT,
      text: _L.GL_TARGET_WEIGHT_ON_PLATFORM,
      index: 0,
      status: wizardState >= WIZARD_STATE_SET_TARGET_WEIGHT ? "complete" : "incomplete",
      showInStepCounter: true,
    },
    {
      ident: WIZARD_STATE_GET_READY_PLATFORM,
      name: _L.GL_GET_READY_PLATFORM,
      text: _L.GL_MAKE_SURE_THE_PLATFORM,
      index: 0,
      status: wizardState >= WIZARD_STATE_GET_READY_PLATFORM ? "complete" : "incomplete",
      showInStepCounter: true,
    },
    {
      ident: WIZARD_STATE_STABILISING_PLATFORM_EMPTY,
      name: _L.GL_STABILISING_PLATFORM,
      text: _L.GL_PLEASE_WAIT,
      index: 0,
      status: wizardState >= WIZARD_STATE_STABILISING_PLATFORM_EMPTY ? "complete" : "incomplete",
      showInStepCounter: false,
    },
    {
      ident: WIZARD_STATE_PLACE_WEIGHT_ON_PLATFORM,
      name: (_L.GL_PLACE_WEIGHT_ON_PLATFORM).replace("{}",""),
      text: (_L.GL_PLACE_WEIGHT_ON_PLATFORM).replace("{}",`${preferredWeight} ${weightUnits()}`),
      //`Place Weight ${preferredWeight} ${weightUnits()} on Platform`, //
      index: 0,
      status: wizardState >= WIZARD_STATE_PLACE_WEIGHT_ON_PLATFORM ? "complete" : "incomplete",
      showInStepCounter: true,
    },
    {
      ident: WIZARD_STATE_STABILISING_PLATFORM_REFERENCE,
      name: _L.GL_STABILISING_PLATFORM,
      text: _L.GL_PLEASE_WAIT,
      index: 1,
      status: wizardState >= WIZARD_STATE_STABILISING_PLATFORM_REFERENCE ? "complete" : "incomplete",
      showInStepCounter: false,
    },
    {
      ident: WIZARD_STATE_STABILISING_PLATFORM_FINISHED,
      name: _L.GL_START_WEIGHING_WITH_PLATFORM,
      text: _L.GL_YOUR_PLATFORM_IS_READY_TO_USE,
      index: 1,
      status: wizardState >= WIZARD_STATE_STABILISING_PLATFORM_FINISHED ? "complete" : "incomplete",
      showInStepCounter: true,
    },
  ];

  const currentStep = steps[wizardState - 1];


  const renderState = () => {
    
    return (
      <>
        <nav aria-label="Progress">
          <ol
            role="list"
            className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
          >
            {steps
              .filter((step) => step.showInStepCounter)
              .map((step, stepIdx, visibleSteps) => (
                <li key={step.name} className="relative md:flex md:flex-1">
                  {step.status === "complete" ? (
                    <div className="group flex w-full items-center">
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span
                          className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full  ${
                            wizardState === stepIdx + 1
                              ? "bg-white border-2 border-indigo-500"
                              : "bg-green-600"
                          }`}
                        >
                          {wizardState === stepIdx + 1 && (
                            <span className="text-indigo-500">
                              {stepIdx + 1}
                            </span>
                          )}
                          {wizardState !== stepIdx + 1 && (
                            <CheckIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center px-6 py-4 text-sm font-medium">
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-grey-300">
                        <span className="text-gray-500 ">{stepIdx + 1}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-500 ">
                        {step.name}
                      </span>
                    </div>
                  )}
                  {stepIdx !== visibleSteps.length - 1 ? (
                    <>
                      <div
                        className="absolute right-0 top-0 hidden h-full w-5 md:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
          </ol>
        </nav>
        
        <div className="text-xl text-center mt-6 py-12 border border-gray-100 rounded-md bg-gray-50">
        {(currentStep.ident == WIZARD_STATE_STABILISING_PLATFORM_EMPTY || 
          currentStep.ident == WIZARD_STATE_STABILISING_PLATFORM_REFERENCE) ? (
          <div>

            {currentStep.index==0 ? (
              _L.GL_MEASURING_EMPTY_PLATFORM
            ) : (
              <>
              {_L.GL_MEASURING_WEIGHT_ON_PLATFORM}
              {recvState.referenceWeight} {weightUnits()}
              </>
            ) }
            <br></br>
            <div className="flex items-center justify-center">
              <div className="w-1/2">
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                        {recvState.percentages?.toFixed(0) || 0}&nbsp;%
                      </span>
                    </div>
                    {/* <div className="text-right">
                      <span className="text-xs font-semibold inline-block text-blue-600">
                        0&nbsp;g /{" "}
                        {recvState.referenceWeight || "0"}&nbsp;g
                      </span>
                    </div> */}
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                    <div
                      style={{ width: (recvState.percentages || 0) + "%" }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <span>
              {currentStep.text} <br></br>
            </span>
          </div>
        ) : (
          currentStep.text
        )}

        {currentStep.ident == WIZARD_STATE_SET_TARGET_WEIGHT && (
          <>
          <div className="text-center">
            <input
              type="text"
              id="preferred_weight"
              name="preferred_weight"
              className={`form-control block mx-auto mt-10 w-40 rounded-md border-0 py-4 px-3 text-black shadow-sm ring-1 ring-inset ${
                error ? "ring-red-500 focus:ring-red-500" : "ring-gray-300 focus:ring-blue-300"
                    } placeholder:text-gray-400 sm:text-sm sm:leading-6`}

              value={preferredWeight}
              onChange={(e) => setPreferredWeight(e.target.value)}
            />
            <div className="text-sm text-red-500 mt-2">{error}</div> {/* Zobrazeni chybove zpravy */}
          </div>
          {weightUnits()}
          </>
        )}


        {currentStep.ident == WIZARD_STATE_GET_READY_PLATFORM && (
          <>
        <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
          <div  className="rounded-md mx-auto my-3 max-w-5xl bg-blue-100 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-blue-900">{_L.GL_INFO_BUTTON_CONTROL}</p>
              </div>
            </div>
          </div>
        </div>
        </>
        )}
        {currentStep.ident == WIZARD_STATE_STABILISING_PLATFORM_FINISHED && 
            <div> 
                <button  className="my-2 text-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto" onClick={onOverview}>
                    {_L.GL_OVERVIEW}
                </button>

                {!weighingState && (
                    <button className="my-2 ml-3 text-center rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" onClick={onStartWeighing}>
                        {_L.GL_START_WEIGHING}
                    </button>
                )}
            </div>
        }
        </div>
        </>
    );
  };

  //const currentStep = steps[wizardState - 1];

  return (
    <div>
      <div className="calibration">
        <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
          <header className="mx-auto my-3 max-w-5xl mt-10">
            <h1 className="text-2xl font-semibold leading-tight tracking-tight text-black">
              {_L.GL_CALIBRATE_SCALE} 
              {/* -- ImmediateWizardState={immediateWizardState} -- wizardState = {wizardState} */}
            </h1>
          </header>
          <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
            <div className="px-6 py-5 sm:p-6 col-span-1 sm:col-span-2">
              <div>
                <h4 className="sr-only">{_L.GL_CALIBRATE_STATUS}</h4>
              </div>
              {renderState()}

              {(currentStep.ident == WIZARD_STATE_SET_TARGET_WEIGHT || 
                currentStep.ident == WIZARD_STATE_GET_READY_PLATFORM || 
                currentStep.ident == WIZARD_STATE_PLACE_WEIGHT_ON_PLATFORM) && 
              <div className="buttons mt-6 flex">
                <button
                  onClick={() => handleNextStep(wizardState)} // Použij handleNextStep pro validaci
                  className="btn btn-info my-2 mr-3 w-full text-center rounded-md bg-green-600 px-3.5 py-5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  to="/start-weighing"
                >
                  {_L.GL_NEXT_STEP}
                </button>

                {/* bg-white   text-gray-900 */}
                <button  className="my-2 text-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto" 
                  onClick={onCancel}>
                    {_L.GL_CANCEL}
                </button>

              </div>
              
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalibrateScale;
