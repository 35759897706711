import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { getWsUrl,isProductionVersion } from '../config'
import { useScaleParams } from "../hooks/useScaleParams";
import { _L } from '../localization.js'
import { ConstructErrorList } from '../errors.js'
import Overview from './Overview'

const Home = () => {
  
  const [message,setMessage] = useState('')
  const [recvState,setRecvState] = useState({})
  const [connected, setConnected] = useState(false)
  const [val, setVal] = useState({overview:{}});
  const [weighingInterval,setWeighingInterval] = useState({begin:"n/a",end:"n/a"})
  const ws = useRef(null);
  
  const {versionInfo, setVersionInfo,setUse_pounds_instead_of_grams,setErrorList} = useScaleParams();

  useEffect(() => {
    
    const socket = new WebSocket(getWsUrl())
    
    socket.onopen = () => {
      console.log("ws opened")
      setConnected(true)
    }
    
    socket.onclose = () => {
      console.log("ws closed")
      setConnected(false)
    }


    socket.onmessage = event => {

      const obj = JSON.parse(event.data);
      setVersionInfo(obj["version"]);
      const newErrList = ConstructErrorList(obj["data"]["errors"]);
      setErrorList(newErrList);
      setUse_pounds_instead_of_grams(obj["overview"]["use_pounds_instead_of_grams"]);

      //console.log(`use_pounds_instead_of_grams= ${JSON.stringify(obj["overview"]["use_pounds_instead_of_grams"])}`);
      //console.log(`overview.age_days= ${obj.overview.age_days}`)
      //console.log(`overview.age_days_or_weeks_type= ${obj.overview.age_days_or_weeks_type}`)

      //console.log(`overview.age_days= ${} ) && overview.age_days_or_weeks_type},
      setVal(obj);
      setRecvState(obj.data);
      setWeighingInterval(obj["overview"]["weighing_interval"]);
      
    }

    ws.current = socket

    return () => {
      socket.close()
    }

  },[])

  return (
    <div className='mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8'>
    <header className="mx-auto my-3 max-w-5xl mt-10">   
     <h1 className="text-2xl font-semibold leading-tight tracking-tight text-black">{_L.GL_HOME_PAGE}</h1>
    </header>
    <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
    <div className="px-6 py-4 pb-8">

    <div className="flex flex-wrap mb-4">
    {recvState.currentState==='WEIGHING_RUNNING' &&
          <div className="w-full bg-green-50 text-green-600 text-center rounded-md p-12 text-center mb-4"><strong className="sm:flex items-center gap-2 justify-center"><svg className="fade block mx-auto mb-2 sm:mx-0 sm:mb-0" height="12" width="12">
          <circle cx="6" cy="6" r="6" fill="#0C810C" />
        </svg>{_L.GL_WEIGHING_RUNNING}</strong>
        <span className='block mt-1 text-sm'>Every day from {weighingInterval["begin"]} &nbsp;to&nbsp;{weighingInterval["end"]}</span>
            </div>



            }
            {recvState.currentState!=='WEIGHING_RUNNING' &&


<div className="w-full bg-indigo-50 text-indigo-600 text-center rounded-md p-12 text-center mb-4"><strong className="sm:flex items-center gap-2 justify-center"><svg className="block mx-auto mb-2 sm:mx-0 sm:mb-0" height="12" width="12">
<circle cx="6" cy="6" r="6" fill="#C42424" />
</svg>{_L.GL_WEIGHING_IS_STOPPED}</strong>
  </div>

            }
    </div>
    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 lg:w-1/2'>
    {recvState.currentState!=='WEIGHING_RUNNING' &&
    <Link type="button"
        className="btn btn-info mt-0 md:mt-2 mr-3 w-full max-h-[52px]  text-center rounded-md bg-green-600 px-3.5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/start-weighing">
        {_L.GL_START_WEIGHING}
      </Link>
      }
      {recvState.currentState==='WEIGHING_RUNNING' &&
      <Link type="button"
      className="rounded-md bg-white mt-0 md:mt-2 mr-3 px-3.5 py-4 max-h-[52px]  text-sm w-full  text-center font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" 
      to="/stop-weighing">
        {_L.GL_STOP_WEIGHING_2LINES}
      </Link>
}

      <Link type="button"
      className="rounded-md bg-white px-3.5 mt-0 md:mt-2  py-4 max-h-[52px] text-sm w-full text-center font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" 
      to="/calibrate-scale">
       {_L.GL_CALIBRATE_SCALE}
      </Link>
      </div>
    </div>
    </div>

    <Overview version={val.version} overview={val.overview}/>

      {/* {!isProductionVersion() &&
        <>
          <div className='led-green'>Value: {JSON.stringify(val)}</div>
          <div>recvState: state={recvState.currentState} / count={recvState.counter}</div>
          <div>Connected={connected?'T':'F'}</div>
          <div className='led-green'>OVERVIEW: {JSON.stringify(val.overview)}</div>
        </>
      } */}
                    
  </div>
  )

}

export default Home
