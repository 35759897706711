import React from 'react'
import { _L } from '../localization.js'
import { isProductionVersion } from '../config'
const Overview = ({version, overview}) => {

    const weightUnits = () => {
        if (!overview.use_pounds_instead_of_grams) {
            return _L.GL_UNITS_SIGN_GRAMS;
        } else {
            return _L.GL_UNITS_SIGN_POUNDS;
        }
    }


    const stats = [
            { type:'overview', name: _L.GL_AVERAGE_WEIGHT, stat: overview.average_weight_grams?.toFixed(0), units:  weightUnits(), condition: overview.weighing_running },
            { type:'overview', name: _L.GL_ACTUAL_WEIGHT, stat: overview.actual_weight_grams?.toFixed(0), units:  weightUnits(), condition: !overview.weighing_running},
            { type:'overview', name: _L.GL_COUNT, stat: overview.count, units: "", condition: true },
//            { type:'overview', name: _L.GL_AGE, stat: (overview.age_days?.toFixed(1)) + " " + overview.age_days<=1.0? (_L.GL_DAY) : (_L.GL_DAYS) || _L.GL_NO_DATA, condition: !isNaN(overview.age_days) && !overview.age_days_or_weeks_type},
//            { type:'overview', name: _L.GL_AGE, stat: (overview.age_days/7.0)?.toFixed(1) + " " + overview.age_days<=7.0? (_L.GL_WEEK) : (_L.GL_WEEKS) || _L.GL_NO_DATA, condition: !isNaN(overview.age_days) && overview.age_days_or_weeks_type},

            { type:'overview', name: _L.GL_AGE, 
              stat: overview.age_days?
                (overview.age_days.toFixed(0) )
                : 0.0, 
              units: overview.age_days<=1.0? (_L.GL_DAY) : (_L.GL_DAYS), 
              condition: overview.age_days_or_weeks_type==1},
              
              { type:'overview', name: _L.GL_AGE, 
              stat: overview.age_days?
                ((overview.age_days/7.0).toFixed(1))
                : 0.0, 
              units: overview.age_days<=7.0 ? (_L.GL_WEEK) : (_L.GL_WEEKS), 
              condition: overview.age_days_or_weeks_type==0},

            { type:'overview', name: _L.GL_LAST_WEIGHT, stat: +overview.last_sample_grams?.toFixed(0), units: weightUnits(), condition: true },
            { type:'overview', name: _L.GL_TARGET_WEIGHT, stat: overview.target_grams, units: weightUnits(),condition: true },
            { type:'overview', name: _L.GL_AVERAGE_WGT, stat: overview.average_weight_grams, units: weightUnits(),condition: !overview.weighing_running },
            { type:'overview', name: _L.GL_STANDARD_WGT, stat: +overview.sigma?.toFixed(0), units: weightUnits(), condition: true },
            { type:'overview', name: _L.GL_DAILY_GAIN, stat: overview.daily_gain_grams?.toFixed(0), units: weightUnits(), condition: true },
            { type:'overview', name: _L.GL_UNIFORMITY, stat: overview.uniformity_percentages?.toFixed(0), units: "%", condition: true },
            { type:'overview', name: _L.GL_VARIATION, stat: overview.variation, units: "", condition: true },
            { type:'overview', name: _L.GL_SUM_OF_ALL, stat: overview.sum_all_pcs_grams?.toFixed(0), units: weightUnits(),condition: true },
//            { type:'overview', name: _L.GL_PREDICTED_WGT, stat: overview.predicted_weight_grams || _L.GL_NO_DATA, condition: true }, 
        ]
        
        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

  return (
    <div>
            <div className="mx-auto my-3 max-w-5xl mt-10">
                <h2 className="text-base font-semibold leading-6 text-gray-900">{_L.GL_STATISTICS}</h2>
                <div>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        {stats.map((item) => (
                            item.type === 'overview' && item.condition && (
                                <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                    <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                                    {/* <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat} {item.units}</dd> */}
                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                        {item.stat?(<>{item.stat} {item.units}</>):(<>{_L.GL_NO_DATA}</>)}
                                        
                                    </dd>
                                </div>
                            )
                        ))}
                    </dl>
                </div>
            </div>

            <div className="relative my-10">
        </div>
    </div>
  )
}

export default Overview