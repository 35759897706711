import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getRestApiUrl } from '../config';
import { _L } from '../localization.js';
import { RadioGroup } from "@headlessui/react";

const days = [
  { name: _L.GL_DAYS_MENU, value: "days", available: true },
  { name: _L.GL_WEEKS_MENU, value: "weeks", available: true },
];

const weighingModes = [
  { name: _L.GL_AUTOMATIC_W_GAIN, value: "automatic_with_gain", available: true },
  { name: _L.GL_AUTOMATIC_WO_GAIN, value: "automatic_wo_gain", available: true },
];

const weighingPreferences = [
  { name: _L.GL_WEIGHING_STANDARD, value: "standard", available: true },
  { name: _L.GL_WEIGHING_CUSTOMIZED, value: "customized", available: true },
];

const AdvanceSettings = [
  { name: _L.GL_SHOW, value: true, available: true },
  { name: _L.GL_HIDE, value: false, available: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const WeighingSettings = () => {
  const [values, setValues] = useState([]);
  const navigate = useNavigate();
  
  const [selectedDays, setDays] = useState(days[0]);
  const [selectedWeighingMode, setWeighingMode] = useState(weighingModes[0]);
  const [selectedWeighingPreference, setWeighingPreference] = useState(weighingPreferences[0]);
  const [selectedAdvanceSettings, setAdvanceSettings] = useState(AdvanceSettings[0]);

  const changedWeighingPreference = (item) => {
    setWeighingPreference(item);
    if (item.value === "standard") {
      setValues({
        ...values,
        low_pass_length_samples: 6,
        stabilisation_range_percentages: 5,
        stabilisation_time_seconds: 1,
      });
    }
  };


  useEffect(() => {
    axios.get(`${getRestApiUrl()}/api/bat2/settings/weighing`)
      .then((res) => {
        setValues(res.data);
        const getItem = (arr, value) => {
          const ind = arr.map((e) => e.value).indexOf(value);
          return arr[ind === -1 ? 0 : ind];
        };
        setDays(getItem(days, res.data.counting_age));
        setWeighingMode(getItem(weighingModes, res.data.weighing_mode));
        setWeighingPreference(getItem(weighingPreferences, res.data.weighing_preference));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleUpdate = (newValues) => {
    let updatedValues = {
      ...newValues,
      counting_age: selectedDays.value,
      weighing_mode: selectedWeighingMode.value,
      weighing_preference: selectedWeighingPreference.value,
    };

    axios.post(`${getRestApiUrl()}/api/bat2/settings/weighing`, updatedValues)
      .then((res) => {
        console.log(res);
        navigate('/');
      })
      .catch((err) => console.log(err));
  };

// Yup validation schema
const validationSchema = Yup.object({
  correction_percentages: Yup.number()
    .min(0, 'Must be greater than or equal to 0')
    .required('Required'),
  
  correction_start_day: Yup.number()
    .min(0, 'Must be greater than or equal to 0')
    .required('Required'),
  
  correction_end_day: Yup.number()
    .min(0, 'Must be greater than or equal to 0')
    .required('Required'),
  
  uniformity_range_percentages: Yup.number()
    .min(0, 'Must be greater than or equal to 0')
    .required('Required'),
  
  weighing_margin_mixed__above_percentages: Yup.number()
    .min(0, 'Must be greater than or equal to 0')
    .required('Required'),
    // .test(
    //   'is-less-than-or-equal-to-below',
    //   'Above margin must not be greater than Below margin',
    //   function (value) {
    //     const { weighing_margin_mixed__under_percentages } = this.parent;
    //     return value <= weighing_margin_mixed__under_percentages;
    //   }
    // ),
  
   weighing_margin_mixed__under_percentages: Yup.number()
     .min(0, 'Must be greater than or equal to 0')
     .required('Required'),
  //   .test(
  //     'is-greater-than-or-equal-to-above',
  //     'Below margin must not be less than Above margin',
  //     function (value) {
  //       const { weighing_margin_mixed__above_percentages } = this.parent;
  //       return value >= weighing_margin_mixed__above_percentages;
  //     }
  //   ),
  

  low_pass_length_samples: Yup.number()
    .min(1, 'Must be greater than or equal to 1')
    .required('Required'),
  
  stabilisation_range_percentages: Yup.number()
    .min(1, 'Must be greater than or equal to 1')
    .required('Required'),
  
  stabilisation_time_seconds: Yup.number()
    .min(1, 'Must be greater than or equal to 1')
    .required('Required'),
});
  

  return (


    <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8weighing_preference">
    <header className="mx-auto my-3 max-w-5xl mt-10">
      <h1 className="text-2xl font-semibold leading-tight tracking-tight text-black">
        {_L.GL_WEIGHING_SETTINGS_PAGE}
      </h1>
    </header>
    <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
            <div className="px-6 py-5 sm:p-6 col-span-1 sm:col-span-2">
            <Formik
              initialValues={values}
              validationSchema={validationSchema}
              enableReinitialize={true}
              validateOnChange={false} // validace při změně je vypnutá
              validateOnBlur={false}   // validace při opuštění pole je vypnutá
              onSubmit={handleUpdate}
            >
              {({ values, handleChange, touched, errors }) => (
              <Form>
                <label
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {_L.GL_COUNTING_AGE}
                </label>
                <div>
                  <RadioGroup value={selectedDays} onChange={setDays} className="mt-2">
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_COUNTING_AGE_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {days.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                <label className="block mt-6 text-sm font-medium leading-6 text-gray-900">
                  {_L.GL_WEIGHING_MODE}
                </label>
                <div>
                  <RadioGroup value={selectedWeighingMode} onChange={setWeighingMode} className="mt-2">
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_WEIGHING_MODE_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {weighingModes.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                <label className="block mt-6 text-sm font-medium leading-6 text-gray-900">
                  {_L.GL_ADVANCED_SETTINGS_PAGE}
                </label>
                <div>
                <RadioGroup value={selectedAdvanceSettings} onChange={setAdvanceSettings} className="mt-2">
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_USING_DHCP_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {AdvanceSettings.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>


                {selectedAdvanceSettings.value ? 
                (
                  <>
                <label className="block mt-6 text-sm font-medium leading-6 text-gray-900">
                  {_L.GL_WEIGHING_PREFERENCE}
                </label>
                <div>
                  <RadioGroup value={selectedWeighingPreference} onChange={changedWeighingPreference} className="mt-2">
                    <RadioGroup.Label className="sr-only">
                    {_L.GL_WEIGHING_PREFERENCE_PLACEHOLDER}
                    </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-3">
                      {weighingPreferences.map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.available
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active ? "ring-2 ring-blue ring-offset-2" : "",
                              checked
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "ring-1 ring-inset ring-gray-300 bg-white text-white-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1"
                            )
                          }
                          disabled={!option.available}
                        >
                          {option.name}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>                  
                 <h2 className="font-semibold block mt-6 text-sm font-medium leading-6 text-gray-900">
                    {_L.GL_WEIGHT_CORRECTION}
                 </h2>
                    <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            {_L.GL_CORRECTION} [%]
                        </label>
                        <div className="mt-1">
                            <Field
                      name="correction_percentages"
                      type="number"
                      className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.correction_percentages && errors.correction_percentages ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}                      
                      placeholder="Enter Correction %"
                      value={values.correction_percentages}
                      onSubmit={handleChange}
                      onChange={e => setValues({...values, correction_percentages: e.target.value})}
                    />
                    <ErrorMessage name="correction_percentages" component="div" className="text-red-500 text-sm" />

                        </div>
                    </div>
                    <div className="mt-4 columns-2">
    <div>
        <label htmlFor="correction_start_day" className="block text-sm font-medium text-gray-700">
            {_L.GL_CORRECTION_START}
        </label>
        <div className="mt-1">
            <Field
                name="correction_start_day"
                type="number"
                className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.correction_start_day && errors.correction_start_day ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}
                placeholder='Enter Param #1'
                value={values.correction_start_day}
                onChange={e => setValues({ ...values, correction_start_day: e.target.value })}
            />
            <ErrorMessage name="correction_start_day" component="div" className="text-red-500 text-sm" />
        </div>
    </div>
    <div>
        <label htmlFor="correction_end_day" className="block text-sm font-medium text-gray-700">
            {_L.GL_CORRECTION_END}
        </label>
        <div className="mt-1">
            <Field
                name="correction_end_day"
                type="number"
                className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.correction_end_day && errors.correction_end_day ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}                placeholder='Enter Param #2'
                value={values.correction_end_day}
                onChange={e => setValues({ ...values, correction_end_day: e.target.value })}
            />
            <ErrorMessage name="correction_end_day" component="div" className="text-red-500 text-sm" />
        </div>
    </div>
</div>
<h2 className="font-semibold mt-8">
    {_L.GL_STATISTICS}
</h2>
<div className="mt-4">
    <label htmlFor="uniformity_range_percentages" className="block text-sm font-medium text-gray-700">
        {_L.GL_UNIFORMITY_RANGE} [%]
    </label>
    <div className="mt-1">
        <Field
            name="uniformity_range_percentages"
            type="number"
            className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.uniformity_range_percentages && errors.uniformity_range_percentages ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}            placeholder='Enter Param #3'
            value={values.uniformity_range_percentages}
            onChange={e => setValues({ ...values, uniformity_range_percentages: e.target.value })}
        />
        <ErrorMessage name="uniformity_range_percentages" component="div" className="text-red-500 text-sm" />
    </div>
</div>
<h2 className="font-semibold mt-8">
    {_L.GL_WEIGHING_MARGINS}
</h2>
<div className="mt-4 columns-2">
    <div>
        <label htmlFor="weighing_margin_mixed__above_percentages" className="block text-sm font-medium text-gray-700">
            {_L.GL_ABOVE_TARGET} [%]
        </label>
        <div className="mt-1">
            <Field
                name="weighing_margin_mixed__above_percentages"
                type="number"
                className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.weighing_margin_mixed__above_percentages && errors.weighing_margin_mixed__above_percentages ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}                placeholder='Enter Param #4'
                value={values.weighing_margin_mixed__above_percentages}
                onChange={e => setValues({ ...values, weighing_margin_mixed__above_percentages: e.target.value })}
            />
            <ErrorMessage name="weighing_margin_mixed__above_percentages" component="div" className="text-red-500 text-sm" />
        </div>
    </div>
    <div>
        <label htmlFor="weighing_margin_mixed__under_percentages" className="block text-sm font-medium text-gray-700">
            {_L.GL_BELOW_TARGET} [%]
        </label>
        <div className="mt-1">
            <Field
                name="weighing_margin_mixed__under_percentages"
                type="number"
                className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.weighing_margin_mixed__under_percentages && errors.weighing_margin_mixed__under_percentages ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}                placeholder='Enter Param #5'
                value={values.weighing_margin_mixed__under_percentages}
                onChange={e => setValues({ ...values, weighing_margin_mixed__under_percentages: e.target.value })}
            />
            <ErrorMessage name="weighing_margin_mixed__under_percentages" component="div" className="text-red-500 text-sm" />
        </div>
    </div>
</div>
<h2 className="font-semibold mt-8">
    {_L.GL_WEIGHING_ALGORITHM}
</h2>
<div className="mt-4 columns-3">
    <div>
        <label htmlFor="low_pass_length_samples" className="block text-sm font-medium text-gray-700">
            {_L.GL_FILTER}
        </label>
        <div className="mt-1">
            <Field
                name="low_pass_length_samples"
                type="number"
                className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.low_pass_length_samples && errors.low_pass_length_samples ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}                placeholder='Enter Param #6'
                value={values.low_pass_length_samples}
                disabled={selectedWeighingPreference.value === "standard"}
                onChange={e => setValues({ ...values, low_pass_length_samples: e.target.value })}
            />
            <ErrorMessage name="low_pass_length_samples" component="div" className="text-red-500 text-sm" />
        </div>
    </div>
    <div>
        <label htmlFor="stabilisation_range_percentages" className="block text-sm font-medium text-gray-700">
            {_L.GL_STABILIZATION_RANGE} [%]
        </label>
        <div className="mt-1">
            <Field
                name="stabilisation_range_percentages"
                type="number"
                className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.stabilisation_range_percentage && errors.stabilisation_range_percentage ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}                placeholder='Enter Param #7'
                value={values.stabilisation_range_percentages}
                disabled={selectedWeighingPreference.value === "standard"}
                onChange={e => setValues({ ...values, stabilisation_range_percentages: e.target.value })}
            />
            <ErrorMessage name="stabilisation_range_percentages" component="div" className="text-red-500 text-sm" />
        </div>
    </div>
    <div>
        <label htmlFor="stabilisation_time_seconds" className="block text-sm font-medium text-gray-700">
            {_L.GL_STABILIZATION_TIME}
        </label>
        <div className="mt-1">
            <Field
                name="stabilisation_time_seconds"
                type="number"
                className={`form-control block w-full rounded-md border-0 py-2.5 px-3  shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6 ${touched.stabilisation_time_seconds && errors.stabilisation_time_seconds ? 'focus:ring-red-300 ring-red-300 text-red-500' : 'focus:ring-blue-300 ring-gray-300 text-black'}`}                placeholder='Enter Param #8'
                value={values.stabilisation_time_seconds}
                disabled={selectedWeighingPreference.value === "standard"}
                onChange={e => setValues({ ...values, stabilisation_time_seconds: e.target.value })}
                
            />
            <ErrorMessage name="stabilisation_time_seconds" component="div" className="text-red-500 text-sm" />
        </div>
    </div>
</div>
                 </>
                ) : ( <></>)}

                <div className="buttons mt-6">
                  <button type="submit"
                    className="btn btn-info my-2 mr-3 w-full sm:w-72 text-center rounded-md bg-green-600 px-3.5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/start-weighing">
                    {_L.GL_UPDATE}
                  </button>
                </div>
                </Form>
              )}
            </Formik>
            </div>
          </div>
        </div>
    </div>
    
  )
}

export default WeighingSettings