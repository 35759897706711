import React, { useState,useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { getRestApiUrl } from '../config'
import { useScaleParams } from "../hooks/useScaleParams";
import { _L } from '../localization.js'

function StartWeighing() {
  
  const [values,setValues] = useState([])

  useEffect(() => {
    axios.get(`${getRestApiUrl()}/api/bat2/weighing_control`)
    .then(res => {
        console.log(res.data)
        setValues(res.data)
    })
    .catch( err => {
        console.log(err)
    })
  },[])

  const {use_pounds_instead_of_grams} = useScaleParams();

  console.log("use_pounds_instead_of_grams=",use_pounds_instead_of_grams);

  const weightUnits = () => {
    if (!use_pounds_instead_of_grams) {
        return _L.GL_UNITS_SIGN_GRAMS;
    } else {
        return _L.GL_UNITS_SIGN_POUNDS;
    }
}



  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()
    const startValues = { 
        flock_name: values.flock_name,
        initial_age_days : +values.initial_age_days,
        initial_weight_mixed_grams : +values.initial_weight_mixed_grams,
        weighing_interval_begin : values.weighing_interval_begin,
        weighing_interval_end : values.weighing_interval_end
      };

    axios.post(`${getRestApiUrl()}/api/bat2/weighing_control/start`,startValues)
    .then(res => {
        console.log("START VALUES=",startValues)
        console.log(`APIURL=${getRestApiUrl()}`)
        console.log(res)
        navigate('/')
    })
    .catch( err => console.log(err))
  }

  const onCancel = (event) => {
    event.preventDefault();
    navigate('/')
  };

  return (
    <div>
    <div className="mx-auto my-3 max-w-5xl px-4 md:px-6 lg:px-8">
    <header className="mx-auto my-3 max-w-5xl mt-10">
      <h1 className="text-2xl font-semibold leading-tight tracking-tight text-black">
        {_L.GL_START_WEIGHING}
      </h1>
    </header>
    <div className="mx-auto my-3 mt-6 max-w-5xl overflow-hidden rounded-lg bg-white shadow">
    <div className="px-6 py-5 sm:p-6 col-span-1 sm:col-span-2">

          <form onSubmit={handleSubmit}>
          <div className="columns-1 md:columns-3">
          <div className="mb-2">
                    <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="start-day">{_L.GL_START_DAY}</label>
                    <input type="text" name="start-day" 
                        className="form-control block w-full rounded-md border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                        value={values.initial_age_days} 
                        onChange={e => setValues({...values, initial_age_days: e.target.value})}
                    />
                </div>
                <div className="mb-2">
                    <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="initial-weight">{_L.GL_INITIAL_WEIGHT} [{weightUnits()}]</label>
                    <input type="text" name="initial-weight" className="form-control block w-full rounded-md border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                        value={values.initial_weight_mixed_grams} 
                        onChange={e => setValues({...values, initial_weight_mixed_grams: e.target.value})}
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="flock-name">{_L.GL_FLOCK_NAME}</label>
                    <input type="text" name="flock-name" className="form-control block w-full rounded-md border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6"
                        value={values.flock_name}
                        onChange={e => setValues({...values, flock_name: e.target.value})}
                    />
                </div>
                </div>
                <div>

                    <label htmlFor="date-time" className="mb-2 block text-sm font-medium leading-6 text-gray-900">
                    <h2>
                    {_L.GL_WEIGHING_SCHEDULE}
                    </h2>   
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                    <div>
                        <input 
                        type="time" 
                        name="weighing-interval-begin" id="weighing-interval-begin"
                        value={values.weighing_interval_begin}
                        onChange={(e) =>
                          setValues({ ...values, weighing_interval_begin: e.target.value })
                        } 
                        className="block w-full rounded-md border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6" />
                    </div>
                    <div>
                        <input 
                        type="time" 
                        name="weighing-interval-end" id="weighing-interval-end"
                        value={values.weighing_interval_end}
                        onChange={(e) =>
                          setValues({ ...values, weighing_interval_end: e.target.value })
                        } 
                        className="block w-full rounded-md border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-300 sm:text-sm sm:leading-6" />
                    </div>
                    </div>
                </div>
                <div className="buttons md:grid md:grid-cols-3 gap-4 mt-10">
                  <button 
                  
                    className="btn btn-info my-2 mr-3 w-full text-center rounded-md bg-white px-3.5 py-3 md:py-5 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" 
                    onClick={onCancel}>
                    {_L.GL_CANCEL}
                  </button>
                  <button type="submit"
                    className="btn col-span-2 btn-info my-2 mr-3 w-full text-center rounded-md bg-green-600 px-3.5 py-5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" to="/start-weighing">
                    {_L.GL_START_WEIGHING}
                  </button>
                </div>
          </form>
        </div>
        </div>
        </div>
    </div>
  )
}

export default StartWeighing
