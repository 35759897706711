import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { _L } from '../localization.js';
import '../styles.css';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { isProductionVersion } from '../config';
import { useScaleParams } from "../hooks/useScaleParams.js";

const menuItems = [
  { name: _L.GL_HOME_PAGE_MENU, href: '/', current: false, top: true, hasSubmenu: false },
  {
    name: _L.GL_TOP_SETTINGS, href: '/settings/', current: false, top: true, hasSubmenu: true,
    submenu: [
      { name: _L.GL_SCALE_SETTINGS_MENU, href: '/settings/scale-settings', current: false },
      { name: _L.GL_WEIGHING_SETTINGS_MENU, href: '/settings/weighing-settings', current: false },
    ]
  },
  {
    name: _L.GL_SUB_CONNECTIVITY, href: '/connectivity/', current: false, top: true, hasSubmenu: true,
    submenu: [
      { name: _L.GL_ETHERNET_SETTINGS_MENU, href: '/connectivity/ethernet-settings', current: false },
      { name: _L.GL_WIFI_SETTINGS_MENU, href: '/connectivity/wifi-settings', current: false },
      { name: _L.GL_SERVER_SETTINGS_MENU, href: '/connectivity/server-settings', current: false },
    ]
  },
  { name: _L.GL_HELP_MENU, href: '/help', current: false, top: true, hasSubmenu: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const location = useLocation();
  const { scaleName } = useScaleParams();
  
  // State to keep track of the active top-level menu item
  const [activeMenu, setActiveMenu] = useState(null);

  // Update current state of the menu items based on location
  const updatedMenuItems = menuItems.map(item => ({
    ...item,
    current: item.href === '/' ? location.pathname === '/' : location.pathname.includes(item.href), // Special check for "/"
    submenu: item.submenu ? item.submenu.map(subitem => ({
      ...subitem,
      current: location.pathname.includes(subitem.href), // Same for submenu items
    })) : null,
  }));

  // Handle click on top-level menu to display the corresponding submenu
  const handleMenuClick = (item) => {
    if (item.hasSubmenu) {
      setActiveMenu(item.name); // Set the active top-level menu to show its submenu
    } else {
      setActiveMenu(null); // No submenu, clear any previously active menu
    }
  };

  // Reset active menu when navigating to the home page
  useEffect(() => {
    if (location.pathname === '/') {
      setActiveMenu(null); // Reset submenu when on the home page
    }
  }, [location.pathname]); // This effect runs every time the URL changes

  // Get the submenu of the currently active menu
  const activeSubmenu = updatedMenuItems.find(item => item.name === activeMenu)?.submenu || [];

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-white shadow-sm">
        {({ open }) => (
          <div>
            {/* First navigation bar (main menu) */}
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex items-center">
                  <Link className="flex gap-3" to='/' onClick={() => setActiveMenu(null)}>
                    <svg width="86" height="24" viewBox="0 0 86 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-ed178b38="">
                      <g clipPath="url(#clip0_4_23)" data-v-ed178b38="">
                        <path d="M67.353 7.277H58.653V0H85.614V7.277H76.914V24H67.346L67.353 7.277Z" fill="#E11B22" data-v-ed178b38=""></path>
                        <path d="M40.117 0H50.853L64.27 24H53.534L40.117 0Z" fill="#E11B22" data-v-ed178b38=""></path>
                        <path d="M40.559 24H50.409L45.484 15.189L40.559 24Z" fill="#E11B22" data-v-ed178b38=""></path>
                        <path d="M38.556 2.79201L26.7 24H37.435L43.923 12.395L38.556 2.79201Z" fill="#E11B22" data-v-ed178b38=""></path>
                        <path d="M9.568 18.286H15.176C17.176 18.286 18.916 17.848 18.916 16C18.916 14.588 17.83 13.815 15.569 13.815H9.569L9.568 18.286ZM9.568 9.345H14.611C16.611 9.345 17.873 8.908 17.873 7.362C17.873 6.151 16.567 5.715 14.611 5.715H9.568V9.345ZM0 0H17C25.176 0 26.915 3.563 26.915 6.017C26.915 8.438 25.393 9.748 23.088 10.724C25.871 11.462 28.23 13.177 28.23 16.705C28.23 21.511 23.088 24 17.61 24H0V0Z" fill="#E11B22" data-v-ed178b38=""></path>
                      </g>
                      <defs data-v-ed178b38="">
                        <clipPath id="clip0_4_23" data-v-ed178b38="">
                          <rect width="85.617" height="24" fill="white" data-v-ed178b38=""></rect>
                        </clipPath>
                      </defs>
                    </svg>
                    <h1>{scaleName}&nbsp;&nbsp;
                      {!isProductionVersion && (
                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">DEV</span>
                      )}
                    </h1>
                  </Link>
                </div>
                <div className="hidden sm:flex sm:space-x-4">
                  {updatedMenuItems.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'border-indigo-500 text-black hover:text-gray-800'
                          : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-800',
                        'inline-flex items-center border-b-2 px-3 py-2 text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                      onClick={() => handleMenuClick(item)} // Show submenu on click
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            {/* Second navigation bar (submenu) */}
            {activeSubmenu.length > 0 && (
              <div className="bg-gray-100 shadow-sm">
                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-12 space-x-4">
                    {activeSubmenu.map((subitem) => (
                      <Link
                        key={subitem.name}
                        to={subitem.href}
                        className={classNames(
                          subitem.current
                            ? 'border-indigo-500 text-black hover:text-gray-800'
                            : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-800',
                          'inline-flex items-center border-b-2 px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={subitem.current ? 'page' : undefined}
                      >
                        {subitem.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {updatedMenuItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                        : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                      'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                    onClick={() => handleMenuClick(item)} // Show submenu on click (mobile)
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
}